import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { StreappEventsService } from 'src/app/services/streapp-events.service';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent implements OnInit {
  feedbackFormGroup: UntypedFormGroup;
  send: boolean = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private readonly dialogRef: MatDialogRef<FeedbackDialogComponent>,
    private readonly streappService: StreappEventsService
  ) {}

  ngOnInit(): void {
    this.feedbackFormGroup = this._formBuilder.group({
      message: ['', [Validators.required, Validators.maxLength(500)]],
    });
  }

  closeWithConfirm(): void {
    if (!this.feedbackFormGroup.dirty && !this.feedbackFormGroup.valid) {
      return;
    }
    var feedbackData = {
      Message: this.feedbackFormGroup.get('message').value,
    };

    this.streappService.sendFeedback(JSON.stringify(feedbackData)).then(() => {
      this.send = true;
    });
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
