import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Service to make user info changes visible without refreshing

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userInfoSource = new BehaviorSubject<any>(null);
  userInfo$ = this.userInfoSource.asObservable();

  private imageUrlSource = new BehaviorSubject<string | null>(null);
  imageUrl$ = this.imageUrlSource.asObservable();

  private bannerImageUrlSource = new BehaviorSubject<string | null>(null);
  bannerImageUrl$ = this.bannerImageUrlSource.asObservable();

  updateUserInfo(newUserInfo: any) {
    this.userInfoSource.next(newUserInfo);
  }

  updateImageUrl(url: string) {
    this.imageUrlSource.next(url);
  }

  updateBannerImageUrlSource(url: string) {
    this.bannerImageUrlSource.next(url);
  }
}