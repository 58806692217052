import { Component, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserProfile } from 'src/app/models/profile.model';
import { AuthService } from 'src/app/services/auth.service';
import { UpdateLocationDialogComponent } from '../update-location-dialog/update-location-dialog.component';

@Component({
  selector: 'profile-location-field',
  templateUrl: './profile-location-field.component.html',
  styleUrl: './profile-location-field.component.scss',
})
export class ProfileLocationFieldComponent {
  @ViewChild('mapelement') mapelement;

  map: any;

  userProfile: UserProfile;
  locationChanged: boolean = false;
  loading: boolean = true;

  newLocation: string;
  currentLocation: { lat: number; long: number };
  currentRadius: number;

  constructor(
    private readonly authService: AuthService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.authService
      .getClaimData(true)
      .then((data) => {
        this.userProfile = data;

        if (this.userProfile.location) {
          this.currentLocation = {
            long: +this.userProfile.location.split(';')[0],
            lat: +this.userProfile.location.split(';')[1],
          };
          this.currentRadius = +this.userProfile.location.split(';')[3];
        }

        this.initMap();
      })
      .finally(() => {
        this.loading = false;
      });
  }

  initMap() {
    const azurecontrol = require('azure-maps-control');
    const options = {
      authType: 'subscriptionKey',
      subscriptionKey: 'SROjwQ2cy75Xj0GHK5hcjAoPMSAGa7s3ozx1C9mm0RQ',
    };

    //atlas.Map
    this.map = require('azure-maps-control').Map;

    this.map = new azurecontrol.Map(this.mapelement.nativeElement, {
      center: [this.currentLocation.long, this.currentLocation.lat],
      zoom: 15,
      language: 'nl-NL',
      authOptions: options,
    });

    this.map.markers.clear();
    //Create an HTML marker and add it to the map.
    var marker = new azurecontrol.HtmlMarker({
      position: [this.currentLocation.long, this.currentLocation.lat],
      htmlContent: this.getMarkerContent(),
    });

    this.map.markers.add(marker);

    this.map.setUserInteraction({ interactive: false });
  }

  onCustomLocationChanged(newLocation: string): void {
    this.newLocation = newLocation;
    this.locationChanged = true;
    this.resetMapToNewLocation(this.newLocation);
  }

  edit() {
    this.dialog.open<UpdateLocationDialogComponent>(
      UpdateLocationDialogComponent,
      {
        autoFocus: false, // To prevent select state of close button in header
        panelClass: 'medium',
        data: {
          userProfile: this.userProfile,
        },
      }
    );
  }

  getLocationInfo(): string {
    if (!!this.userProfile && !!this.userProfile.location) {
      return this.userProfile.location.split(';')[2];
    }

    return 'Nog geen locatie ingevuld.';
  }

  resetMapToNewLocation(location: string): void {
    if (!!location) {
      this.map.setCamera({
        center: [+location.split(';')[0], +location.split(';')[1]],
        type: 'fly',
      });
      this.map.markers
        .getMarkers()[0]
        .setOptions({
          position: [+location.split(';')[0], +location.split(';')[1]],
        });
    }
  }

  getMarkerContent(): string {
    return '<div class="marker"><div class="pin bounce"><div class="marker-title"><div class="filler"></div><div class="title">Ingestelde locatie<div></div></div></div>';
  }
}
