import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SebbieInfoDialogComponent } from '../sebbie-info-dialog/sebbie-info-dialog.component';

@Component({
  selector: 'sebbie-counter',
  templateUrl: './sebbie-counter.component.html',
  styleUrl: './sebbie-counter.component.scss',
})
export class SebbieCounterComponent {
  @Input() monthlySebbiePoints: number = 0;
  @Input() sebbiePoints: number = 0;

  constructor(private dialog: MatDialog) {}

  openInfoDialog(): void {
    this.dialog.open<SebbieInfoDialogComponent>(SebbieInfoDialogComponent, {
      autoFocus: false, // To prevent select state of close button in header
    });
  }
}
