import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Subject, takeUntil } from 'rxjs';
import { StreappEvent } from 'src/app/models/streappevent.model';
import { AuthService } from 'src/app/services/auth.service';
import { StreappEventsService } from 'src/app/services/streapp-events.service';
import { VideoToThumbnailService } from 'src/app/services/video-to-thumbnail.service';
import { PriceDialogComponent } from '../new-event-form/price-dialog/price-dialog.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AnonymousCredential, BlockBlobClient } from '@azure/storage-blob';
import { MediaType } from 'src/app/models/mediaitem.model';

@Component({
  selector: 'app-add-content-form',
  templateUrl: './add-content-form.component.html',
  styleUrls: ['./add-content-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddContentFormComponent implements OnInit, OnDestroy {
  publishing: boolean = false;
  streappFormGroup: UntypedFormGroup;
  finishedUploading: boolean = false;
  loading: boolean = true;
  event: StreappEvent;
  isReporter: boolean;
  mediaItems: { path: string; id: string; progress: number }[] = [];
  preUploadedMediaItems: {
    file: NgxFileDropEntry;
    previewFile: any;
    price: string;
    suggestedPrice: string;
    priceChanged: boolean;
    finished?: boolean;
    hasError?: boolean;
    progress?: number;
  }[] = [];
  preUploadedShopMediaItems: {
    file: NgxFileDropEntry;
    previewFile: any;
    price: string;
    suggestedPrice: string;
    priceChanged: boolean;
    finished?: boolean;
    hasError?: boolean;
    progress?: number;
  }[] = [];

  private readonly destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly streappService: StreappEventsService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly videoToThumbnailService: VideoToThumbnailService,
    private readonly dialog: MatDialog,
    private readonly materialSnackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.streappFormGroup = this._formBuilder.group({
      file: ['', Validators.required],
    });
    this.getEventIdAndLoadEvent();

    this.isReporter = this.authService.isReporter;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getEventIdAndLoadEvent(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      let id = params['id'];
      this.loadEventFromService(id);
    });
  }

  loadEventFromService(id: string): void {
    this.loading = true;
    this.streappService
      .getEventById(id, this.authService.authorizationHeaderValue)
      .then((data: StreappEvent) => {
        if (!data) {
          // Either event doesn't exists or user is underage and its an highimpact event
          this.router.navigate(['/']);
        }

        this.event = data;

        if (this.event.createdBy !== this.authService.userId) {
          // User is not the owner of this event
          this.router.navigate(['/']);
        }

        this.loading = false;
      })
      .catch((error: HttpErrorResponse) => {
        this.loading = false;
      });
  }

  openPriceSelectionWindow(selectedItem: number, currentList: string): void {
    // Check to not open multiple dialogs
    if (this.dialog.openDialogs.length == 0) {
      // Open popup to update pricing of specific media item
      if (currentList === 'shop') {
        let dialogRef = this.dialog.open(PriceDialogComponent, {
          data: {
            price: this.preUploadedShopMediaItems[selectedItem].price,
            priceSuggestion:
              this.preUploadedShopMediaItems[selectedItem].suggestedPrice,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          // Received data from dialog-component, update price
          if (res.n) {
            this.preUploadedShopMediaItems[selectedItem].priceChanged = true;
            return (this.preUploadedShopMediaItems[selectedItem].price = res.n);
          }
          return;
        });
      } else {
        let dialogRef = this.dialog.open(PriceDialogComponent, {
          data: {
            price: this.preUploadedMediaItems[selectedItem].price,
            priceSuggestion:
              this.preUploadedMediaItems[selectedItem].suggestedPrice,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          // Received data from dialog-component, update price
          if (res.n) {
            this.preUploadedMediaItems[selectedItem].priceChanged = true;
            return (this.preUploadedMediaItems[selectedItem].price = res.n);
          }
          return;
        });
      }
    }
  }

  //function to return list of numbers from 0 to n-1
  numSequence(n: number): Array<number> {
    return Array(n);
  }

  public async dropped(files: NgxFileDropEntry[], uploadSite: string) {
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        if (this.preUploadedMediaItems.length === 0) {
          this.streappFormGroup.controls['file'].setValue(
            droppedFile.relativePath
          );
        }

        // Check to make sure this file hasn't already been added in both instances and all mediaitems list
        if (
          this.preUploadedMediaItems
            .map((item) => item.file.relativePath)
            .indexOf(droppedFile.relativePath) === -1 &&
          this.preUploadedShopMediaItems
            .map((item) => item.file.relativePath)
            .indexOf(droppedFile.relativePath) === -1 &&
          uploadSite == 'none' &&
          this.preUploadedMediaItems.length < 10
        ) {
          await this.saveFileToMediaItems(droppedFile, uploadSite);
        }

        // Check to make sure this file hasn't already been added to both instances and shop-only list
        if (
          this.preUploadedShopMediaItems
            .map((item) => item.file.relativePath)
            .indexOf(droppedFile.relativePath) === -1 &&
          this.preUploadedMediaItems
            .map((item) => item.file.relativePath)
            .indexOf(droppedFile.relativePath) === -1 &&
          uploadSite == 'shop' &&
          this.preUploadedShopMediaItems.length < 10
        ) {
          await this.saveFileToMediaItems(droppedFile, uploadSite);
        }
      }
    }
  }

  async saveFileToMediaItems(
    droppedFile: NgxFileDropEntry,
    saveToList: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      const reader = new FileReader();

      fileEntry.file((file) => {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          if (file.type.startsWith('video') || this.isVideoFile(file.name)) {
            // Create a thumbnail if its a video
            const url = await this.videoToThumbnailService.generateThumbnail(
              file
            );
            // Push video with previewimage and prices to media items
            if (saveToList == 'shop') {
              this.preUploadedShopMediaItems.push({
                file: droppedFile,
                previewFile: url,
                price: '20.00',
                suggestedPrice: '20.00',
                priceChanged: false,
                finished: false,
              });
              resolve();
            } else {
              this.preUploadedMediaItems.push({
                file: droppedFile,
                previewFile: url,
                price: '20.00',
                suggestedPrice: '20.00',
                priceChanged: false,
                finished: false,
              });
              resolve();
            }
          } else if (file.type.startsWith('image')) {
            // Push image with and prices to media items
            if (saveToList == 'shop') {
              this.preUploadedShopMediaItems.push({
                file: droppedFile,
                previewFile: reader.result,
                price: '15.00',
                suggestedPrice: '15.00',
                priceChanged: false,
                finished: false,
              });
              resolve();
            } else {
              this.preUploadedMediaItems.push({
                file: droppedFile,
                previewFile: reader.result,
                price: '15.00',
                suggestedPrice: '15.00',
                priceChanged: false,
                finished: false,
              });
              resolve();
            }
          } else {
            reject('File was not returned as image or video');
          }
        };
      });
    });
  }

  async addContentToStreapp(): Promise<void> {
    this.publishing = true;
    await this.uploadFiles();
  }

  private async uploadFiles(): Promise<void> {
    this.mediaItems = [];
    await this.generateSasAndUpload();
    this.checkAllFilesCompleted();
  }

  private async generateSasAndUpload(): Promise<void> {
    for (let i = 0; i < this.preUploadedMediaItems.length; i++) {
      if (
        !this.mediaItems.find(
          (item) =>
            item.path === this.preUploadedMediaItems[i].file.relativePath
        )
      ) {
        this.mediaItems.push({
          path: this.preUploadedMediaItems[i].file.relativePath,
          id: '',
          progress: 0,
        });
      }

      if (this.preUploadedMediaItems[i].finished) {
        continue;
      }

      const fileEntry = this.preUploadedMediaItems[i].file
        .fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: File) => {
        const mediaType = file.type.startsWith('image')
          ? MediaType.Image
          : MediaType.Video;
        const sasResult = await this.streappService.getUploadSasUrl(
          this.authService.authorizationHeaderValue,
          mediaType,
          this.event.shockingEvent || this.event.injuriesPresent === 'ja',
          this.event.partitionKey,
          file.name.substr(file.name.lastIndexOf('.')).toLowerCase(),
          false,
          this.preUploadedMediaItems[i].price
        );
        const blobClient = new BlockBlobClient(
          sasResult.token,
          new AnonymousCredential()
        );
        const buffer = await file.arrayBuffer();
        const res = await blobClient.uploadData(buffer);
        if (!res.errorCode) {
          this.preUploadedMediaItems[i].finished = true;
          const mediaItem = this.mediaItems.find(
            (item) =>
              item.path === this.preUploadedMediaItems[i].file.relativePath
          );
          if (mediaItem) {
            mediaItem.id = sasResult.mediaItemId;
          }

          this.checkAllFilesCompleted();
        }
      });
    }

    for (let i = 0; i < this.preUploadedShopMediaItems.length; i++) {
      if (
        !this.mediaItems.find(
          (item) =>
            item.path === this.preUploadedShopMediaItems[i].file.relativePath
        )
      ) {
        this.mediaItems.push({
          path: this.preUploadedShopMediaItems[i].file.relativePath,
          id: '',
          progress: 0,
        });
      }

      if (this.preUploadedShopMediaItems[i].finished) {
        continue;
      }

      const fileEntry = this.preUploadedShopMediaItems[i].file
        .fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: File) => {
        const mediaType = file.type.startsWith('image')
          ? MediaType.Image
          : MediaType.Video;
        const sasResult = await this.streappService.getUploadSasUrl(
          this.authService.authorizationHeaderValue,
          mediaType,
          this.event.shockingEvent,
          this.event.partitionKey,
          file.name.substr(file.name.lastIndexOf('.')).toLowerCase(),
          true,
          this.preUploadedShopMediaItems[i].price
        );
        const blobClient = new BlockBlobClient(
          sasResult.token,
          new AnonymousCredential()
        );
        const buffer = await file.arrayBuffer();
        const res = await blobClient.uploadData(buffer);
        if (!res.errorCode) {
          this.preUploadedShopMediaItems[i].finished = true;
          const mediaItem = this.mediaItems.find(
            (item) =>
              item.path === this.preUploadedShopMediaItems[i].file.relativePath
          );
          if (mediaItem) {
            mediaItem.id = sasResult.mediaItemId;
          }

          this.checkAllFilesCompleted();
        }
      });
    }
  }

  private async checkAllFilesCompleted() {
    // Check if all media items are properly uploaded
    // otherwise, return
    if (this.mediaItems.filter((x) => !x.id).length > 0) {
      return;
    }

    if (!this.publishing) {
      // we encountered an error, so return
      return;
    }

    this.finishedUploading = true;

    // Navigate to detail page
    this.streappService.setSelectedEvent(null);
    this.router.navigate(['detail', this.event.partitionKey]);
  }

  getProgress(): string {
    const completed = this.mediaItems.filter((x) => x.id).length;
    const total = this.mediaItems.length;
    return `${completed} van de ${total} voltooid`;
  }

  private isVideoFile(fileName: string): boolean {
    const videoExtensions = [
      '.mp4',
      '.avi',
      '.wmv',
      '.mov',
      '.flv',
      '.mkv',
      '.m4v',
      '.mpg',
      '.mpeg',
      '.webm',
      '.3gp',
      '.ogv',
      '.m2v',
      '.m2ts',
      '.mts',
      '.ts',
      '.vob',
      '.divx',
      '.xvid',
      '.rm',
      '.rmvb',
      '.asf',
      '.f4v',
      '.mpe',
      '.mpv',
      '.mpeg4',
      '.qt',
      '.dav',
      '.swf',
    ];

    const extension = fileName.substr(fileName.lastIndexOf('.')).toLowerCase();
    return videoExtensions.includes(extension);
  }

  removeFile(i: number, listName: string): void {
    if (listName == 'shop') {
      this.preUploadedShopMediaItems.splice(i, 1);
    } else {
      this.preUploadedMediaItems.splice(i, 1);
      if (this.preUploadedMediaItems.length === 0) {
        this.streappFormGroup.controls['file'].setValue('');
      }
    }
  }

  cancel(): void {
    this.router.navigate(['/detail', this.event.partitionKey]);
  }

  publish(): void {
    this.addContentToStreapp();
  }
}
