import { Component, OnInit } from '@angular/core';
import { StreappEvent } from 'src/app/models/streappevent.model';
import { AuthService } from 'src/app/services/auth.service';
import { StreappEventsService } from 'src/app/services/streapp-events.service';

@Component({
  selector: 'my-streapps',
  templateUrl: './my-streapps.component.html',
  styleUrls: ['./my-streapps.component.scss'],
})
export class MyStreAppsComponent implements OnInit {
  myEvents: Array<StreappEvent> = [];
  loading: boolean = true;
  isReporter: boolean = false;
  errorText: string;

  constructor(
    private readonly authService: AuthService,
    private readonly streappService: StreappEventsService
  ) {}

  ngOnInit(): void {
    this.isReporter = this.authService.isReporter;
    this.loadStreappEvents();
  }

  loadStreappEvents(): void {
    this.authService.isReporter;
    this.streappService
      .getMyEvents(this.authService.authorizationHeaderValue)
      .then((data) => {
        this.myEvents = data;
      })
      .catch((error) => {
        this.errorText = error;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
