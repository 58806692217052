import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { StreappEventsService } from '../../../services/streapp-events.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { EventType } from './_models/eventtype.model';
import { StreappEvent } from '../../../models/streappevent.model';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SimilarEventsDialogComponent } from './similar-events-dialog/similar-events-dialog.component';
import { CustomEventsService } from '@piwikpro/ngx-piwik-pro';
import { Tracking } from 'src/app/utilities/tracking';
import { formatDate } from '@angular/common';
import moment from 'moment';
import { VideoToThumbnailService } from 'src/app/services/video-to-thumbnail.service';
import { PriceDialogComponent } from './price-dialog/price-dialog.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AnonymousCredential, BlockBlobClient } from '@azure/storage-blob';
import { MediaType } from 'src/app/models/mediaitem.model';

@Component({
  selector: 'app-new-event-form',
  templateUrl: './new-event-form.component.html',
  styleUrls: ['./new-event-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewEventFormComponent implements OnInit, AfterViewInit {
  datumTijd: string;
  @ViewChild('stepper') matStepper: MatStepper;
  streappFormGroup: UntypedFormGroup;
  selectedEvent: EventType;
  selectedSubEvent: EventType;
  preUploadedMediaItems: {
    file: NgxFileDropEntry;
    previewFile: any;
    price: string;
    suggestedPrice: string;
    priceChanged: boolean;
    finished?: boolean;
    hasError?: boolean;
    progress?: number;
  }[] = [];
  preUploadedShopMediaItems: {
    file: NgxFileDropEntry;
    previewFile: any;
    price: string;
    suggestedPrice: string;
    priceChanged: boolean;
    finished?: boolean;
    hasError?: boolean;
    progress?: number;
  }[] = [];
  publishing: boolean = false;
  selectedLatLng: any;
  linkedEvent: StreappEvent;
  showAdditionalQuestions: boolean = false;
  availableEventTypes: Array<EventType> = [];
  eventID: string;
  finishedUploading: boolean = false;
  mediaItems: { path: string; id: string; progress: number }[] = [];
  minDate: string;
  maxDate: string;
  dateError: string = '';
  isReporter: boolean;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private readonly streappService: StreappEventsService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly customEventService: CustomEventsService,
    private readonly videoToThumbnailService: VideoToThumbnailService,
    private readonly materialSnackbar: MatSnackBar
  ) {}

  ngOnInit() {
    this.initDateTimes();

    this.streappService.getEventTypes().then((data) => {
      this.availableEventTypes = data;
    });

    this.streappFormGroup = this._formBuilder.group({
      file: ['', Validators.required],
      titel: ['', [Validators.required, Validators.maxLength(150)]],
      beschrijving: ['', [Validators.required, Validators.maxLength(5000)]],
      datumTijd: [this.datumTijd],
      tags: ['', Validators.maxLength(200)],
      location: ['', Validators.required],
      hulpdiensten: ['', Validators.required],
      gewonden: ['', Validators.required],
      heftigEvent: ['', Validators.required],
    });

    this.streappFormGroup.controls['heftigEvent'].valueChanges.subscribe(
      (value) => {
        this.showAdditionalQuestionsForShockingEvent(value);
      }
    );

    this.streappFormGroup.get('datumTijd').valueChanges.subscribe((value) => {
      this.validateInputDate(value);
    });

    this.isReporter = this.authService.isReporter;
  }

  initDateTimes(): void {
    this.datumTijd = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.datumTijd += 'T' + formatDate(new Date(), 'HH:mm', 'en');

    const max = new Date();
    this.maxDate = formatDate(max, 'yyyy-MM-dd', 'en');
    this.maxDate += 'T' + formatDate(new Date(), 'HH:mm', 'en');

    const min = new Date();
    min.setDate(min.getDate() - 2);
    this.minDate = formatDate(min, 'yyyy-MM-dd', 'en');
    this.minDate += 'T' + formatDate(new Date(), 'HH:mm', 'en');
  }

  ngAfterViewInit() {
    //Tracks steps in the create event process and sends them to tracking software
    this.matStepper.selectionChange.subscribe((step) => {
      if (step.selectedIndex >= step.previouslySelectedIndex) {
        this.customEventService.trackEvent(
          Tracking.categories[0],
          Tracking.actions[0],
          'Volgende',
          step.selectedIndex
        );
      } else {
        this.customEventService.trackEvent(
          Tracking.categories[0],
          Tracking.actions[0],
          'Vorige',
          step.selectedIndex
        );
      }
    });
  }

  openPriceSelectionWindow(selectedItem: number, currentList: string): void {
    // Check to not open multiple dialogs
    if (this.dialog.openDialogs.length == 0) {
      // Open popup to update pricing of specific media item
      if (currentList === 'shop') {
        let dialogRef = this.dialog.open(PriceDialogComponent, {
          data: {
            price: this.preUploadedShopMediaItems[selectedItem].price,
            priceSuggestion:
              this.preUploadedShopMediaItems[selectedItem].suggestedPrice,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          // Received data from dialog-component, update price
          if (res.n) {
            this.preUploadedShopMediaItems[selectedItem].priceChanged = true;
            return (this.preUploadedShopMediaItems[selectedItem].price = res.n);
          }
          return;
        });
      } else {
        let dialogRef = this.dialog.open(PriceDialogComponent, {
          data: {
            price: this.preUploadedMediaItems[selectedItem].price,
            priceSuggestion:
              this.preUploadedMediaItems[selectedItem].suggestedPrice,
          },
        });

        dialogRef.afterClosed().subscribe((res) => {
          // Received data from dialog-component, update price
          if (res.n) {
            this.preUploadedMediaItems[selectedItem].priceChanged = true;
            return (this.preUploadedMediaItems[selectedItem].price = res.n);
          }
          return;
        });
      }
    }
  }

  //function to return list of numbers from 0 to n-1
  numSequence(n: number): Array<number> {
    return Array(n);
  }

  selectEventType(eventType: string): void {
    this.selectedEvent = this.availableEventTypes.find(
      (x) => x.title === eventType
    );
    this.matStepper.selected.completed = true;

    this.showAdditionalQuestions = this.selectedEvent.isHighImpact;
    this.toggleAdditionalQuestionsValidation(this.showAdditionalQuestions);

    // Send clicked category name to analytics
    this.customEventService.trackEvent(
      Tracking.categories[0],
      Tracking.actions[3],
      this.selectedEvent.title
    );

    this.matStepper.next();
  }

  toggleAdditionalQuestionsValidation(enableRequiredValidation): void {
    if (enableRequiredValidation) {
      this.streappFormGroup
        .get('hulpdiensten')
        .setValidators([Validators.required]);
      this.streappFormGroup
        .get('gewonden')
        .setValidators([Validators.required]);
    } else {
      this.streappFormGroup.get('hulpdiensten').setValidators(null);
      this.streappFormGroup.get('gewonden').setValidators(null);
    }

    this.streappFormGroup.get('hulpdiensten').updateValueAndValidity();
    this.streappFormGroup.get('gewonden').updateValueAndValidity();
  }

  async selectSubEventType(eventType: string): Promise<void> {
    this.selectedSubEvent = this.selectedEvent.children.find(
      (x) => x.title === eventType
    );
    this.matStepper.selected.completed = true;

    // Send clicked subcategory name to analytics
    this.customEventService.trackEvent(
      Tracking.categories[0],
      Tracking.actions[4],
      this.selectedSubEvent.title
    );
    this.matStepper.next();
  }

  showAdditionalQuestionsForShockingEvent(heftigEvent: string): void {
    this.sendAdultContentData(heftigEvent);
    if (heftigEvent === 'ja') {
      this.showAdditionalQuestions = true;
      this.toggleAdditionalQuestionsValidation(true);
    } else {
      this.showAdditionalQuestions = this.selectedEvent.isHighImpact;
      this.toggleAdditionalQuestionsValidation(this.showAdditionalQuestions);
    }
  }

  public async dropped(files: NgxFileDropEntry[], uploadSite: string) {
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        if (this.preUploadedMediaItems.length === 0) {
          this.streappFormGroup.controls['file'].setValue(
            droppedFile.relativePath
          );
        }

        // Check to make sure this file hasn't already been added in both instances and all mediaitems list
        if (
          this.preUploadedMediaItems
            .map((item) => item.file.relativePath)
            .indexOf(droppedFile.relativePath) === -1 &&
          this.preUploadedShopMediaItems
            .map((item) => item.file.relativePath)
            .indexOf(droppedFile.relativePath) === -1 &&
          uploadSite == 'none' &&
          this.preUploadedMediaItems.length < 10
        ) {
          await this.saveFileToMediaItems(droppedFile, uploadSite);
        }

        // Check to make sure this file hasn't already been added to both instances and shop-only list
        if (
          this.preUploadedShopMediaItems
            .map((item) => item.file.relativePath)
            .indexOf(droppedFile.relativePath) === -1 &&
          this.preUploadedMediaItems
            .map((item) => item.file.relativePath)
            .indexOf(droppedFile.relativePath) === -1 &&
          uploadSite == 'shop' &&
          this.preUploadedShopMediaItems.length < 10
        ) {
          await this.saveFileToMediaItems(droppedFile, uploadSite);
        }
      }
    }
  }

  async saveFileToMediaItems(
    droppedFile: NgxFileDropEntry,
    saveToList: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      const reader = new FileReader();

      fileEntry.file((file) => {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          if (file.type.startsWith('video') || this.isVideoFile(file.name)) {
            // Create a thumbnail if its a video
            const url = await this.videoToThumbnailService.generateThumbnail(
              file
            );
            // Push video with previewimage and prices to media items
            if (saveToList == 'shop') {
              this.preUploadedShopMediaItems.push({
                file: droppedFile,
                previewFile: url,
                price: '20.00',
                suggestedPrice: '20.00',
                priceChanged: false,
                finished: false,
              });
              resolve();
            } else {
              this.preUploadedMediaItems.push({
                file: droppedFile,
                previewFile: url,
                price: '20.00',
                suggestedPrice: '20.00',
                priceChanged: false,
                finished: false,
              });
              resolve();
            }
          } else if (file.type.startsWith('image')) {
            // Push image with and prices to media items
            if (saveToList == 'shop') {
              this.preUploadedShopMediaItems.push({
                file: droppedFile,
                previewFile: reader.result,
                price: '15.00',
                suggestedPrice: '15.00',
                priceChanged: false,
                finished: false,
              });
              resolve();
            } else {
              this.preUploadedMediaItems.push({
                file: droppedFile,
                previewFile: reader.result,
                price: '15.00',
                suggestedPrice: '15.00',
                priceChanged: false,
                finished: false,
              });
              resolve();
            }
          } else {
            reject('File was not returned as image or video');
          }
        };
      });
    });
  }

  validateInputDate(value: string): void {
    const created = moment(value)
      .utcOffset(new Date().getTimezoneOffset())
      .toDate();
    const min = new Date();
    min.setDate(min.getDate() - 2);

    if (created > new Date() || created < min) {
      this.streappFormGroup.controls['datumTijd'].setErrors({
        incorrect: true,
      });
    } else {
      this.streappFormGroup.controls['datumTijd'].setErrors(null);
    }
  }

  eventData: any;

  private setEventData(): any {
    const created = moment(this.streappFormGroup.get('datumTijd').value)
      .utcOffset(new Date().getTimezoneOffset())
      .toDate();
    this.eventData = {
      ID: this.eventID,
      Title: !!this.linkedEvent
        ? this.linkedEvent.title
        : this.streappFormGroup.get('titel').value,
      Description: this.streappFormGroup.get('beschrijving').value,
      Category: this.selectedEvent.title,
      Subcategory: this.selectedSubEvent.title,
      InjuriesPresent: this.streappFormGroup.get('gewonden').value,
      EmergencyServicesPresent:
        this.streappFormGroup.get('hulpdiensten').value === 'ja',
      ShockingEvent: this.streappFormGroup.get('heftigEvent').value === 'ja',
      Tags: !!this.linkedEvent
        ? this.linkedEvent.tags
        : this.streappFormGroup.get('tags').value,
      Latitude: !!this.linkedEvent
        ? this.linkedEvent.latitude
        : this.selectedLatLng[1],
      Longtitude: !!this.linkedEvent
        ? this.linkedEvent.longtitude
        : this.selectedLatLng[0],
      LinkedToMainStreappID: !!this.linkedEvent
        ? this.linkedEvent.partitionKey
        : '',
      Created: created,
    };
  }

  async createEvent(): Promise<void> {
    this.publishing = true;

    this.setEventData();

    if (!this.eventID) {
      try {
        this.eventID = await this.streappService.initStreappEvent(
          JSON.stringify(this.eventData),
          this.authService.authorizationHeaderValue
        );
      } catch (error) {
        console.log(error);
        this.publishing = false;

        this.materialSnackbar.open(
          `Fout bij aanmaken StreApp: ${error}. Probeer het nogmaals.`,
          'Ok',
          {
            duration: 5000,
          }
        );
      }
    }
    await this.uploadFiles();
  }

  async uploadFiles(): Promise<void> {
    this.mediaItems = [];
    await this.generateSasAndUpload();
    this.checkAllFilesCompleted();
  }

  private processFile(
    promiseArray: any,
    mediaItem: any,
    shopOnly: boolean
  ): void {
    if (
      !this.mediaItems.find((item) => item.path === mediaItem.file.relativePath)
    ) {
      this.mediaItems.push({
        path: mediaItem.file.relativePath,
        id: '',
        progress: 0,
      });
      const fileEntry = mediaItem.file.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        promiseArray.push(
          this.uploadFile(
            mediaItem.file.relativePath,
            file,
            mediaItem.price,
            this.eventID,
            shopOnly
          )
        );
      });
    }
  }

  private async checkAllFilesCompleted() {
    // Check if all media items are properly uploaded
    // otherwise, return
    if (this.mediaItems.filter((x) => !x.id).length > 0) {
      return;
    }

    if (!this.publishing) {
      // we encountered an error, so return
      return;
    }

    this.finishedUploading = true;
    var eventData = {
      ID: this.eventID,
    };
    try {
      await this.streappService.completeStreappEvent(
        JSON.stringify(eventData),
        this.authService.authorizationHeaderValue
      );
    } catch (error) {
      console.log(error);
      this.materialSnackbar.open(
        `Fout bij afronden StreApp: ${error}. Probeer het nogmaals.`,
        'Ok',
        {
          duration: 5000,
        }
      );
    }

    // Navigate to detail page
    this.streappService.setSelectedEvent(null);
    this.router.navigate(['detail', this.eventID]);
  }

  uploadFile(
    fileRelativePath: string,
    file: File,
    price: string,
    eventID: string,
    uploadRestriction: boolean
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      if (file.type.startsWith('image')) {
        formData.append('imageStream', file, fileRelativePath);

        this.streappService
          .uploadImage(
            formData,
            price,
            eventID,
            this.authService.authorizationHeaderValue,
            uploadRestriction
          )
          .then((data) => {
            this.mediaItems.find((i) => i.path === fileRelativePath).id = data;
            if (
              this.preUploadedMediaItems.find(
                (i) => i.file.relativePath === fileRelativePath
              )
            ) {
              this.preUploadedMediaItems.find(
                (i) => i.file.relativePath === fileRelativePath
              ).finished = true;
              this.preUploadedMediaItems.find(
                (i) => i.file.relativePath === fileRelativePath
              ).hasError = false;
            }
            if (
              this.preUploadedShopMediaItems.find(
                (i) => i.file.relativePath === fileRelativePath
              )
            ) {
              this.preUploadedShopMediaItems.find(
                (i) => i.file.relativePath === fileRelativePath
              ).finished = true;
              this.preUploadedShopMediaItems.find(
                (i) => i.file.relativePath === fileRelativePath
              ).hasError = false;
            }

            this.checkAllFilesCompleted();
            resolve();
          })
          .catch((error) => {
            console.log('fout' + error);
            this.publishing = false;

            if (
              this.preUploadedMediaItems.find(
                (i) => i.file.relativePath === fileRelativePath
              )
            ) {
              this.preUploadedMediaItems.find(
                (i) => i.file.relativePath === fileRelativePath
              ).hasError = true;
            }
            if (
              this.preUploadedShopMediaItems.find(
                (i) => i.file.relativePath === fileRelativePath
              )
            ) {
              this.preUploadedShopMediaItems.find(
                (i) => i.file.relativePath === fileRelativePath
              ).hasError = true;
            }

            this.materialSnackbar.open(
              `Fout bij uploaden afbeelding: ${fileRelativePath}. Probeer het nogmaals.`,
              'Ok',
              {
                duration: 5000,
              }
            );
          });
      } else if (file.type.startsWith('video') || this.isVideoFile(file.name)) {
        formData.append('videoStream', file, fileRelativePath);

        this.streappService
          .uploadVideo(
            formData,
            price,
            eventID,
            this.authService.authorizationHeaderValue,
            uploadRestriction
          )
          .subscribe(
            (data) => {
              this.mediaItems.find(
                (i) => i.path === fileRelativePath
              ).progress = data.progress;

              if (
                this.preUploadedMediaItems.find(
                  (i) => i.file.relativePath === fileRelativePath
                )
              ) {
                this.preUploadedMediaItems.find(
                  (i) => i.file.relativePath === fileRelativePath
                ).progress = data.progress;
                this.preUploadedMediaItems.find(
                  (i) => i.file.relativePath === fileRelativePath
                ).hasError = false;
              }
              if (
                this.preUploadedShopMediaItems.find(
                  (i) => i.file.relativePath === fileRelativePath
                )
              ) {
                this.preUploadedShopMediaItems.find(
                  (i) => i.file.relativePath === fileRelativePath
                ).progress = data.progress;
                this.preUploadedShopMediaItems.find(
                  (i) => i.file.relativePath === fileRelativePath
                ).hasError = false;
              }

              if (data.state === 'DONE') {
                this.mediaItems.find((i) => i.path === fileRelativePath).id =
                  data.body;

                if (
                  this.preUploadedMediaItems.find(
                    (i) => i.file.relativePath === fileRelativePath
                  )
                ) {
                  this.preUploadedMediaItems.find(
                    (i) => i.file.relativePath === fileRelativePath
                  ).finished = true;
                  this.preUploadedMediaItems.find(
                    (i) => i.file.relativePath === fileRelativePath
                  ).hasError = false;
                }

                if (
                  this.preUploadedShopMediaItems.find(
                    (i) => i.file.relativePath === fileRelativePath
                  )
                ) {
                  this.preUploadedShopMediaItems.find(
                    (i) => i.file.relativePath === fileRelativePath
                  ).finished = true;
                  this.preUploadedShopMediaItems.find(
                    (i) => i.file.relativePath === fileRelativePath
                  ).hasError = false;
                }

                this.checkAllFilesCompleted();
                resolve();
              }
            },
            (error) => {
              console.log(error);
              this.publishing = false;

              if (
                this.preUploadedMediaItems.find(
                  (i) => i.file.relativePath === fileRelativePath
                )
              ) {
                this.preUploadedMediaItems.find(
                  (i) => i.file.relativePath === fileRelativePath
                ).hasError = true;
              }
              if (
                this.preUploadedShopMediaItems.find(
                  (i) => i.file.relativePath === fileRelativePath
                )
              ) {
                this.preUploadedShopMediaItems.find(
                  (i) => i.file.relativePath === fileRelativePath
                ).hasError = true;
              }

              this.materialSnackbar.open(
                `Fout bij uploaden video: ${error}. Probeer het nogmaals.`,
                'Ok',
                {
                  duration: 5000,
                }
              );
            }
          );
      } else {
        reject('unknown file type encountered');
      }
    });
  }

  getProgress(): string {
    const completed = this.mediaItems.filter((x) => x.id).length;
    const total = this.mediaItems.length;
    return `${completed} van de ${total} voltooid`;
  }

  isVideoFile(fileName: string): boolean {
    const videoExtensions = [
      '.mp4',
      '.avi',
      '.wmv',
      '.mov',
      '.flv',
      '.mkv',
      '.m4v',
      '.mpg',
      '.mpeg',
      '.webm',
      '.3gp',
      '.ogv',
      '.m2v',
      '.m2ts',
      '.mts',
      '.ts',
      '.vob',
      '.divx',
      '.xvid',
      '.rm',
      '.rmvb',
      '.asf',
      '.f4v',
      '.mpe',
      '.mpv',
      '.mpeg4',
      '.qt',
      '.dav',
      '.swf',
    ];

    const extension = fileName.substr(fileName.lastIndexOf('.')).toLowerCase();
    return videoExtensions.includes(extension);
  }

  removeFile(i: number, listName: string): void {
    if (listName == 'shop') {
      let path = this.preUploadedShopMediaItems[i].file.relativePath;
      this.preUploadedShopMediaItems.splice(i, 1);
    } else {
      let path = this.preUploadedMediaItems[i].file.relativePath;
      this.preUploadedMediaItems.splice(i, 1);

      if (this.preUploadedMediaItems.length === 0) {
        this.streappFormGroup.controls['file'].setValue('');
      }
    }
  }

  setLocation(location: any) {
    this.streappFormGroup
      .get('location')
      .setValue(`${location[1]} , ${location[0]}`);
    this.selectedLatLng = location;
    this.customEventService.trackEvent(
      Tracking.categories[0],
      Tracking.actions[0],
      'SelectLocation'
    );
  }

  sendAdultContentData(input: string): void {
    // Send changed input of Adult Content selector to tracking data
    this.customEventService.trackEvent(
      Tracking.categories[0],
      Tracking.actions[6],
      input
    );
  }

  sendWoundedData(input: string): void {
    // Send changed input of wounded selector to tracking data
    this.customEventService.trackEvent(
      Tracking.categories[0],
      Tracking.actions[7],
      input
    );
  }

  sendEmergencyServicesData(input: string): void {
    // Send changed input of Emergency Services selector to tracking data
    this.customEventService.trackEvent(
      Tracking.categories[0],
      Tracking.actions[8],
      input
    );
  }

  checkSimilarEvents(): void {
    this.streappService
      .getNearbyEvents(
        this.selectedLatLng[0],
        this.selectedLatLng[1],
        this.authService.authorizationHeaderValue
      )
      .then((data: StreappEvent[]) => {
        if (!data || data.length === 0) {
          this.linkedEvent = null;
          this.toggleRequiredValidators(true);
          this.matStepper.next();
          return;
        }

        // There are similar events, show these in a popup so the user can choose to link rather then create a new event
        this.dialog
          .open<SimilarEventsDialogComponent>(SimilarEventsDialogComponent, {
            data: data,
          })
          .afterClosed()
          .subscribe(
            (selectedEvent: { result: string; event: StreappEvent }) => {
              if (!selectedEvent) {
                return;
              }

              if (selectedEvent.result === 'link') {
                this.linkedEvent = selectedEvent.event;
                this.selectedEvent = this.availableEventTypes.find(
                  (type) => type.title === this.linkedEvent.category
                );
                this.selectedSubEvent = this.selectedEvent?.children.find(
                  (type) => type.title === this.linkedEvent.subcategory
                );
                this.toggleRequiredValidators(false);
                this.matStepper.next();
              } else if (selectedEvent.result === 'new') {
                this.linkedEvent = null;
                this.toggleRequiredValidators(true);
                this.matStepper.next();
              }
            }
          );
      });
  }

  toggleRequiredValidators(enableRequiredValidation: boolean): void {
    if (enableRequiredValidation) {
      this.streappFormGroup
        .get('titel')
        .setValidators([Validators.required, Validators.maxLength(150)]);
    } else {
      this.streappFormGroup.get('titel').setValidators(null);
    }

    this.streappFormGroup.get('titel').updateValueAndValidity();
  }

  async generateSasAndUpload(): Promise<void> {
    for (let i = 0; i < this.preUploadedMediaItems.length; i++) {
      if (
        !this.mediaItems.find(
          (item) =>
            item.path === this.preUploadedMediaItems[i].file.relativePath
        )
      ) {
        this.mediaItems.push({
          path: this.preUploadedMediaItems[i].file.relativePath,
          id: '',
          progress: 0,
        });
      }

      if (this.preUploadedMediaItems[i].finished) {
        continue;
      }

      const fileEntry = this.preUploadedMediaItems[i].file
        .fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: File) => {
        const mediaType = file.type.startsWith('image')
          ? MediaType.Image
          : MediaType.Video;
        const sasResult = await this.streappService.getUploadSasUrl(
          this.authService.authorizationHeaderValue,
          mediaType,
          this.eventData.ShockingEvent || this.eventData.InjuriesPresent === 'ja',
          this.eventID,
          file.name.substr(file.name.lastIndexOf('.')).toLowerCase(),
          false,
          this.preUploadedMediaItems[i].price
        );
        const blobClient = new BlockBlobClient(
          sasResult.token,
          new AnonymousCredential()
        );
        const buffer = await file.arrayBuffer();
        const res = await blobClient.uploadData(buffer);
        if (!res.errorCode) {
          this.preUploadedMediaItems[i].finished = true;
          const mediaItem = this.mediaItems.find(
            (item) =>
              item.path === this.preUploadedMediaItems[i].file.relativePath
          );
          if (mediaItem) {
            mediaItem.id = sasResult.mediaItemId;
          }

          this.checkAllFilesCompleted();
        }
      });
    }

    for (let i = 0; i < this.preUploadedShopMediaItems.length; i++) {
      if (
        !this.mediaItems.find(
          (item) =>
            item.path === this.preUploadedShopMediaItems[i].file.relativePath
        )
      ) {
        this.mediaItems.push({
          path: this.preUploadedShopMediaItems[i].file.relativePath,
          id: '',
          progress: 0,
        });
      }

      if (this.preUploadedShopMediaItems[i].finished) {
        continue;
      }

      const fileEntry = this.preUploadedShopMediaItems[i].file
        .fileEntry as FileSystemFileEntry;
      fileEntry.file(async (file: File) => {
        const mediaType = file.type.startsWith('image')
          ? MediaType.Image
          : MediaType.Video;
        const sasResult = await this.streappService.getUploadSasUrl(
          this.authService.authorizationHeaderValue,
          mediaType,
          this.eventData.ShockingEvent,
          this.eventID,
          file.name.substr(file.name.lastIndexOf('.')).toLowerCase(),
          true,
          this.preUploadedShopMediaItems[i].price
        );
        const blobClient = new BlockBlobClient(
          sasResult.token,
          new AnonymousCredential()
        );
        const buffer = await file.arrayBuffer();
        const res = await blobClient.uploadData(buffer);
        if (!res.errorCode) {
          this.preUploadedShopMediaItems[i].finished = true;
          const mediaItem = this.mediaItems.find(
            (item) =>
              item.path === this.preUploadedShopMediaItems[i].file.relativePath
          );
          if (mediaItem) {
            mediaItem.id = sasResult.mediaItemId;
          }

          this.checkAllFilesCompleted();
        }
      });
    }
  }
}
