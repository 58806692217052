import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { StreappEventsService } from 'src/app/services/streapp-events.service';
import { SimpleTextDialogComponent } from '../simple-text-dialog/simple-text-dialog.component';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrl: './contact-dialog.component.scss',
})
export class ContactDialogComponent {
  nameFilledOnInit: boolean = false;
  emailFilledOnInit: boolean = false;
  contactFormGroup: UntypedFormGroup;

  constructor(
    private readonly dialogRef: MatDialogRef<ContactDialogComponent>,
    private readonly dialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
    private readonly streappService: StreappEventsService,
    private readonly authService: AuthService
  ) {
    this.dialogRef.disableClose = true;
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    const name: string = this.authService.userName;
    const email: string = this.authService.email;

    this.contactFormGroup = this._formBuilder.group({
      name: [name || '', [Validators.required, Validators.maxLength(150)]],
      email: [email || '', [Validators.required, Validators.email]],
      message: ['', [Validators.required, Validators.maxLength(1000)]],
    });

    if (!!name) {
      this.contactFormGroup.get('name').disable();
      this.nameFilledOnInit = true;
    }

    if (!!email) {
      this.contactFormGroup.get('email').disable();
      this.emailFilledOnInit = true;
    }
  }

  sendContact() {
    if (this.contactFormGroup.valid) {
      var contactData = {
        Name: this.contactFormGroup.get('name').value,
        Email: this.contactFormGroup.get('email').value,
        Message: this.contactFormGroup.get('message').value,
      };

      this.streappService.sendContact(JSON.stringify(contactData));

      this.clearForm();

      this.dialogRef.close();

      this.dialog.open<SimpleTextDialogComponent>(SimpleTextDialogComponent, {
        data: {
          title: 'contact.email_send_title',
          text: 'contact.email_send',
          showButton: false,
        },
      });
    }
  }

  clearForm() {
    //reset form
    this.contactFormGroup = this._formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(150)]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required, Validators.maxLength(1000)]],
    });
  }
}
