import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { SimpleTextDialogComponent } from 'src/app/components/simple-text-dialog/simple-text-dialog.component';
import { UserProfile } from 'src/app/models/profile.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'update-location-dialog',
  templateUrl: './update-location-dialog.component.html',
  styleUrl: './update-location-dialog.component.scss',
})
export class UpdateLocationDialogComponent implements OnInit, AfterViewInit {
  userProfile: UserProfile = this.data.userProfile;

  @ViewChild('mapelement') mapelement;

  map: any;

  locationChanged: boolean = false;
  newLocation: string;
  currentLocation: { lat: number; long: number };
  currentRadius: number;

  constructor(
    private authService: AuthService,
    private legacyDialog: MatLegacyDialog,
    private readonly dialogRef: MatDialogRef<UpdateLocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      userProfile: UserProfile;
    }
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if (this.userProfile.location) {
      this.currentLocation = {
        long: +this.userProfile.location.split(';')[0],
        lat: +this.userProfile.location.split(';')[1],
      };
      this.currentRadius = +this.userProfile.location.split(';')[3];
    }
  }

  ngAfterViewInit(): void {
    let timeout = 5; //miliseconds
    setTimeout(() => {
      //Wait so that map has time to generate correctly on dialog width
      this.initMap();
    }, timeout);
  }

  initMap() {
    const azurecontrol = require('azure-maps-control');
    const options = {
      authType: 'subscriptionKey',
      subscriptionKey: 'SROjwQ2cy75Xj0GHK5hcjAoPMSAGa7s3ozx1C9mm0RQ',
    };

    //atlas.Map
    this.map = require('azure-maps-control').Map;

    this.map = new azurecontrol.Map(this.mapelement.nativeElement, {
      center: [this.currentLocation.long, this.currentLocation.lat],
      zoom: 15,
      language: 'nl-NL',
      authOptions: options,
    });

    this.map.markers.clear();
    //Create an HTML marker and add it to the map.
    var marker = new azurecontrol.HtmlMarker({
      position: [this.currentLocation.long, this.currentLocation.lat],
      htmlContent: this.getMarkerContent(),
    });

    this.map.markers.add(marker);

    this.map.setUserInteraction({ interactive: false });
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    var profile = {
      birthday: this.userProfile.birthDay,
      location: this.newLocation + ';' + this.currentRadius,
    };

    this.authService.updateProfile(JSON.stringify(profile)).then((data) => {
      this.dialogRef.close();

      this.legacyDialog.open<SimpleTextDialogComponent>(
        SimpleTextDialogComponent,
        {
          data: {
            title: 'profile.save.popup_title',
            text: 'profile.save.popup_text',
            showButton: false,
          },
        }
      );

      this.authService.loginSilent().then(() => {
        this.userProfile.location = this.newLocation + ';' + this.currentRadius;
        this.locationChanged = false;
      });
    });
  }

  onCustomLocationChanged(newLocation: string): void {
    this.newLocation = newLocation;
    this.locationChanged = true;
    this.resetMapToNewLocation(this.newLocation);
  }

  getLocationInfo(): string {
    if (!!this.userProfile && !!this.userProfile.location) {
      return this.userProfile.location.split(';')[2];
    }

    return 'Nog geen locatie ingevuld.';
  }

  resetMapToNewLocation(location: string): void {
    if (!!location) {
      this.map.setCamera({
        center: [+location.split(';')[0], +location.split(';')[1]],
        type: 'fly',
      });
      this.map.markers
        .getMarkers()[0]
        .setOptions({
          position: [+location.split(';')[0], +location.split(';')[1]],
        });
    }
  }

  getMarkerContent(): string {
    return '<div class="marker"><div class="pin bounce"><div class="marker-title"><div class="filler"></div><div class="title">Ingestelde locatie<div></div></div></div>';
  }
}
